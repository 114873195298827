export const isProduction = !(/localhost|192/.test(window.location.href))
export const http = isProduction ? 'https://' : ''
export const domain = isProduction ? 'admin.lemazzxc.com' : ''
export const host = `${http}${domain}/api`

export const cacheKeys = {
  token: 'TOKEN_CACHE',
  admin: 'ADMIN_CACHE',
  isSuper: 'IS_SUPER_CACHE'
}
