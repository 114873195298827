import {
  CarOutlined,
  LinkOutlined,
  TeamOutlined,
  WalletOutlined,
  ShopOutlined,
  UnorderedListOutlined,
  PieChartOutlined,
  CreditCardOutlined,
  AccountBookOutlined,
  FundOutlined
} from '@ant-design/icons'
import { Grid } from 'antd-mobile'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchRefundMessage, fetchTotalData, fetchAdminDetail } from '../../api'
import { format } from '../../utils/price'
import { get, set, remove } from '../../utils/storage'
import { cacheKeys } from '../../utils/constant'

export default function Nav() {
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [msg, setMsg] = useState(0)
  const [isSuper, setIsSuper] = useState(0)

  useEffect(() => {
    fetchAdminDetail({}).then(res => {
      setIsSuper(res.data.is_super)
      set(cacheKeys.isSuper, `${res.data.is_super}`, new Date().getTime() + 1 * 60 * 60 * 24)
    })
    fetchTotalData().then(res => {
      setTotal(res.data.incomeToday)
    })
    fetchRefundMessage().then(res => {
      setMsg(res.data.num)
    })
  }, [])

  return (
    <div>
      {
        isSuper !== 2 && (
          <div className="flex-center flex-column bg-fff" style={{ height: 100 }}>
            <div className="fs-14">今日总收益</div>
            <div className="fs-26 mt-4">¥ {format(total)}</div>
          </div>
        )
      }
      <Grid className="bg-fff" columns={3} gap={8}>
        {
          isSuper !== 2 && (
            <Grid.Item
          className="flex-center flex-column pt-16 pb-16"
          onClick={() => {
            navigate('/data-list')
          }}
        >
          <PieChartOutlined style={{ fontSize: 24, marginBottom: 2 }} />
          <div className="mt-4 fs-12">数据统计</div>
        </Grid.Item>
          )
        }
        {
          isSuper === 1 && (
            <>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/transaction')
                }}
              >
                <FundOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">交易数据</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/users')
                }}
              >
                <TeamOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">用户管理</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/shop-list')
                }}
              >
                <ShopOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">店铺/设备管理</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/shop-package')
                }}
              >
                <CarOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">洗车套餐管理</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/shop-recharge')
                }}
              >
                <WalletOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">充值套餐管理</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/orders')
                }}
              >
                <UnorderedListOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">订单列表</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                style={{ position: 'relative' }}
                onClick={() => {
                  navigate('/refund')
                }}
              >
                {msg > 0 ? (
                  <div
                    className="flex-center fs-14"
                    style={{
                      position: 'absolute',
                      right: 24,
                      top: 4,
                      height: 20,
                      width: 20,
                      borderRadius: '50%',
                      backgroundColor: 'red',
                      color: '#fff'
                    }}
                  >
                    {msg}
                  </div>
                ) : (
                  <></>
                )}
                <AccountBookOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">余额退款</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/meituan')
                }}
              >
                <LinkOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">美团授权</div>
              </Grid.Item>
              <Grid.Item
                className="flex-center flex-column pt-16 pb-16"
                onClick={() => {
                  navigate('/free-park')
                }}
              >
                <CreditCardOutlined style={{ fontSize: 24, marginBottom: 2 }} />
                <div className="mt-4 fs-12">洗车免停管理</div>
              </Grid.Item>
            </>
          )
        }
        {
          isSuper === 0 && (
            <Grid.Item
              className="flex-center flex-column pt-16 pb-16"
              onClick={() => {
                navigate('/transaction')
              }}
            >
              <FundOutlined style={{ fontSize: 24, marginBottom: 2 }} />
              <div className="mt-4 fs-12">交易数据</div>
            </Grid.Item>
          )
        }
        {
          // 物业管理只需要展示免停管理功能
          isSuper === 2 && (
            <Grid.Item
              className="flex-center flex-column pt-16 pb-16"
              onClick={() => {
                navigate('/free-park')
              }}
            >
              <CreditCardOutlined style={{ fontSize: 24, marginBottom: 2 }} />
              <div className="mt-4 fs-12">洗车免停管理</div>
            </Grid.Item>
          )
        }
      </Grid>
      <div className="flex-center fs-10 pt-14" style={{ color: '#aaa' }}>
        v3.8.22
      </div>
    </div>
  )
}

