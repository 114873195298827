import { domain } from '../utils/constant'
import { http } from '../utils/http'

export async function fetchUsers(pageSize: number, page: number, search: string, shopId: number) {
  return http.get(
    `/user/list?page=${page}&page_size=${pageSize}&search=${search}${shopId === 0 ? '' : `&shop_id=${shopId}`}`
  )
}

export async function fetchOrders(pageSize: number, page: number, keyword?: string) {
  let query = `page=${page}&page_size=${pageSize}`
  if (keyword) {
    query += `&search=${keyword}`
  }
  return http.get(`/order/list?${query}`)
}

export async function fetchUser(id: string, shop_id?: string) {
  let query = `?user_id=${id}`
  if (shop_id) {
    query = `${query}&shop_id=${shop_id}`
  }
  return http.get(`/user/detail${query}`)
}

export async function fetchTotalData() {
  return http.get(`/analysis/income`)
}

export async function fetchDataList(shop_id?: string, start_time?: string, end_time?: string) {
  let query = []
  if (shop_id) {
    query.push(`shop_id=${shop_id}`)
  }
  if (start_time) {
    query.push(`start_time=${start_time}`)
  }
  if (end_time) {
    query.push(`end_time=${end_time}`)
  }
  return http.get(`/analysis/count${query.length ? `?${query.join('&')}` : ''}`)
}

export async function fetchUserOrders(
  id: string,
  type: string,
  other: {
    page_size: number
    page: number
    order_no: string
    start_time: string // 2024-01-01 00:00:00
    end_time: string
  }
) {
  let query = `user_id=${id}&type=${type}&start_time=${other.start_time}&end_time=${other.end_time}&page=${other.page}&page_size=${other.page_size}`
  if (other.order_no) {
    query += `&order_no=${other.order_no}`
  }
  return http.get(`/amount/list?${query}`)
}

export async function setUserBalance(user_id: string, amount: number, shop_id: any) {
  return http.post('/amount/balance', {
    user_id,
    amount,
    shop_id
  })
}

export async function reduceBalance(user_id: string, amount: number, shop_id: any) {
  return http.post('/amount/reduce', {
    user_id,
    amount,
    shop_id
  })
}

export async function fetchMeituanSession(auth_code: string) {
  return http.post('/receipt/session', {
    auth_code,
    redirect_url: `https://${domain}/callback`
  })
}

export async function fetchShopList(page: number, pageSize: number) {
  return http.get(`/shop/list?page=${page}&page_size=${pageSize}`)
}

export async function fetchRefundList() {
  return http.get(`/refund/list`)
}

export async function fetchRefundMessage() {
  return http.get(`/refund/message`)
}

export async function cancelRefund(form: AnyObject) {
  return http.post(`/refund/cancel`, form)
}

export async function okRefund(form: AnyObject) {
  return http.post(`/refund/confirm`, form)
}

export async function createShop(form: any) {
  return http.post(`/shop/add`, form)
}

export async function updateShop(form: any) {
  return http.post(`/shop/update`, form)
}

export async function fetchShopRecharge() {
  return http.get(`/recharge/list`)
}

export async function updateShopRecharge(form: {
  recharge_id: number
  shop_id: number
  amount: number
  status: number
  bonus_amount: number
  is_recommend: number
}) {
  return http.post(`/recharge/update`, form)
}

export async function createShopRecharge(form: {
  shop_id: number
  amount: number
  status: number
  bonus_amount: number
  is_recommend: number
}) {
  return http.post(`/recharge/add`, form)
}

export async function fetchShopPackage() {
  return http.get(`/commodity/list`)
}

export async function updateShopPackage(form: any) {
  return http.post(`/commodity/update`, form)
}

export async function createShopPackage(form: any) {
  return http.post(`/commodity/add`, form)
}

export async function stopDevice(form: { sn: string }) {
  return http.post('/device/shutdown', form)
}

export async function updateDevice(form: any) {
  return http.post('/device/update', form)
}

export async function updateDeviceIntelligent(form: any) {
  return http.post('/device/setIntelligent', form)
}

export async function updateDeviceRate(form: any) {
  return http.post('/device/setRate', form)
}

/**
 * 入场记录
 * /park/list
 * @param page
 * @param pageSize
 * @returns
 */
export async function fetchAdmissionList(form: { page?: number, page_size?: number, search?: string, shop_id?: string, start_time?: string, end_time?: string, direction: string }) {
  let query = []
  if (form.shop_id) {
    query.push(`shop_id=${form.shop_id}`)
  }
  if (form.search) {
    query.push(`search=${form.search}`)
  }
  if (form.start_time) {
    query.push(`start_time=${form.start_time}`)
  }
  if (form.end_time) {
    query.push(`end_time=${form.end_time}`)
  }
  // return http.get(`/analysis/count${query.length ? `?${query.join('&')}` : ''}`)

  return http.get(
    // `/park/list?page=${page}&page_size=${pageSize}&search=${search}${shopId === 0 ? '' : `&shop_id=${shopId}`}`
    `/park/list?page=${form.page}&page_size=${form.page_size}&direction=${form.direction}${query.length ? `&${query.join('&')}` : ''}`
  )
}

/**
 * 拉黑
 * /park/list
 * @returns
 */
export async function updatePark(form: {park_id: number, park_status: number}) {
  return http.post('/park/update', form)
}

/**
 * 管理员详情
 * /admin/detail
 * @returns
 */
export async function fetchAdminDetail(form: {}) {
  return http.post('/admin/detail', form)
}

/**
 * 交易记录
 * /analysis/transaction
 * @returns
 */
export async function fetchAnalysisTransaction(form: {shop_id?: string | number, month?: string}) {
  return http.get(`/analysis/transaction?shop_id=${form.shop_id}&month=${form.month}`)
}

/**
 * 免停功能：发送优惠券
 * /park/send
 * @returns
 */
export async function sendCoupon(form: {phone: number, license_plate: string}) {
  return http.post('/park/send', form)
}
